import __ from 'lodash/fp/__'
import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import sumBy from 'lodash/fp/sumBy'
import flow from 'lodash/fp/flow'
import curry from 'lodash/fp/curry'
import difference from 'lodash/fp/difference'
import remove from 'lodash/fp/remove'
import equals from 'lodash/fp/equals'

import { QueryClient } from '@tanstack/react-query'
import { TCart, TCartItem, TCartItems } from '@tofu/shared/types/cart'
import { TVariantPortionSize } from '@tofu/shared/types/product'
import { isFiloPie } from '@tofu/shared/utils/products'
import {
  TNormalisedProduct,
  TNormalisedProductsRecord,
  TNormalisedProductVariant
} from '@tofu/shared/utils/products-normaliser'

const getItems = get('items')
const getVariantId = get('variant_id')
const getProductId = get('product_id')
const getProductType = get('product_type')
const equalsDelivery = equals('DELIVERY')

export const getVariantIds = map(getVariantId)
export const getItemsVariantIds = flow(getItems, getVariantIds)
export const getItemsProductIds = flow(getItems, getProductId)

const isDeliveryItem = flow(getProductType, equalsDelivery)

export const removeDeliveryItemFromCartItems = remove(isDeliveryItem)

export const getItemQuantityByProductId = (
  product_id: number,
  cartItems: TCartItems
) => flow(filter({ product_id }), sumBy('quantity'))(cartItems)

/**
 * This function returns any of the ids that aren't already in the cart
 */
export const omitDishesAlreadyInCart = (cart: TCart, ids: number[]) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  difference(ids, getItemsVariantIds(cart))

/**
 * Returns a cart item with the given id and cart
 */
export const getCartItemById = curry((id: number, cart: TCart) =>
  flow(getItems, find({ id }))(cart)
)

/**
 * Returns a cart item with the given variant_id and cart
 */
export const getCartItemByVariantId = curry((variant_id: number, cart: TCart) =>
  flow(getItems, find({ variant_id }))(cart)
)

export const getPortionSizeFromCart = (
  cart: TCart
): TVariantPortionSize | null =>
  cart?.portion_size && cart?.portion_size !== 'NO_MEALS'
    ? cart.portion_size
    : null

export const hasDeliveryCharge = (basketItems: TCartItems | undefined) =>
  basketItems?.some((item) => item.product_type === 'DELIVERY') ?? false

export const getSubtotal = ({
  basket,
  deliveryCharge
}: {
  basket: TCart
  deliveryCharge: number
}) => {
  if (!basket?.subtotal_price) {
    return null
  }
  return (
    (hasDeliveryCharge(basket.items)
      ? basket.subtotal_price - deliveryCharge * 100
      : basket.subtotal_price) / 100
  )
}

export const getProductFromVariantId = (
  variantId: number,
  products: TNormalisedProductsRecord
): {
  product: TNormalisedProduct
  variant: TNormalisedProductVariant
} | null => {
  let matchingVariant = null

  const product =
    products &&
    Object.values(products).find((product) => {
      const variants = Object.values(product.variants)
      const variant = variants.find(
        (variant) => variant && variant.id === variantId
      )

      if (variant) {
        matchingVariant = variant
        return true
      }

      return false
    })

  if (!product || !matchingVariant) {
    return null
  }

  return { product, variant: matchingVariant }
}

export const getItemCount = (items: TCartItems) => {
  let count = 0
  items?.forEach((item) => {
    if (item.product_type !== 'DELIVERY') {
      count += item.quantity
    }
  })
  return count
}

export const getProductFromCartItemId = (
  cartItemId: number,
  products: TNormalisedProductsRecord,
  cartItems: TCartItem[]
): {
  product: TNormalisedProduct
  variant: TNormalisedProductVariant
  cartItem: TCartItem
} | null => {
  const cartItem = cartItems.find(
    (item: { id: number }) => item.id === cartItemId
  )

  if (!cartItem) {
    return null
  }

  const match = getProductFromVariantId(cartItem.variant_id, products)

  if (!match) {
    return null
  }

  return { ...match, cartItem }
}

export const getServingSize = (
  product: TNormalisedProduct,
  portionSize: TCart['portion_size']
): string => {
  if (isFiloPie(product)) return '2 - 4'
  switch (product?.product_type) {
    case 'BREAKFAST':
    case 'SMOOTHIE':
    case 'PIZZA':
    case 'TREAT':
      return '1'
    case 'SIDE':
      return '2'
    case 'TRAYBAKE':
      return '4'
    default:
      return portionSize === 'SINGLE_PORTION' ? '1' : '2'
  }
}

/**
 * Either wait for the cart to be refetched then show a toast message with cart status,
 * or refetch the cart and don't wait for a response
 */
export const invalidateQueryAndMaybeShowToast =
  (queryClient: QueryClient, cartToken: string) =>
  async (_: TCartItem, { cb }: { cb?: (cart: TCart) => void }) => {
    if (cb) {
      await queryClient.invalidateQueries(['getCart', cartToken])
      const updatedCart = queryClient.getQueryData([
        'getCart',
        cartToken
      ]) as TCart

      cb(updatedCart)
    } else {
      queryClient.invalidateQueries(['getCart', cartToken])
    }
  }
