export const environment = {
  account_base_url: process.env['ACCOUNT_BASE_URL'],
  api_base_url: process.env['API_BASE_URL'],
  checkout_base_url: process.env['CHECKOUT_BASE_URL'],
  shop_base_url: process.env['SHOP_BASE_URL'],
  environment: process.env['ENVIRONMENT'],
  gtm_id: process.env['GTM_ID'],
  one_trust_id: process.env['ONE_TRUST_ID'],
  prismic_repo: process.env['PRISMIC_REPO'],
  mentionme_url: process.env['MENTIONME_URL'],
  release: process.env['NOW_GITHUB_COMMIT_SHA'],
  sentry_dns: process.env['PEANUT_SENTRY_DNS'],
  statsig_client_sdk_key: process.env['NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY'],
  statsig_client_sdk_key_middleware:
    process.env['NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY_MIDDLEWARE'],
  statsig_server_api_key: process.env['STATSIG_SERVER_API_KEY'],
  statsig_server_api_key_middleware:
    process.env['STATSIG_SERVER_API_KEY_MIDDLEWARE'],
  stripe_key: process.env['STRIPE_KEY'],
  vwo_sdk_key: process.env['VWO_SDK_KEY'],
  vwo_account_id: process.env['VWO_ACCOUNT_ID'],
  cloudimage_token: process.env['CLOUDIMAGE_TOKEN'],
  uuid_cookie: 'session_a_uuid'
}
